import { PanelItem } from '@osjs/panels';
import { h } from 'hyperapp';
import * as CryptoJS from "crypto-js";

// For Encryption/Decryption
const encryptDecryptValuePassword = "fHnP9iso5WM68B82LlWMtxT";
const encryptDecryptKeyPassword = "sK0Re5q5GRADKJxWd1yhBgp";
const salt = CryptoJS.enc.Hex.parse("4acfedc7dc72a9003a0dd721d7642bde");
const iv = CryptoJS.enc.Hex.parse("69135769514102d0eded589ff874cacd");

export default class LoggedInUser extends PanelItem {
  // You can set your own state and actions by overriding the init method
  init() {
    super.init({
      // State
      name: this.getLoggedInUser()
    }, {
      // Actions
    })
  }

  getLoggedInUser() {
    const user = this.decryptFromLocalStorage("user");
    let name = "";
    if (user) {
      if (this.valueIsDefined(user.SURNAME)) {
        name = user.SURNAME;
        if (this.valueIsDefined(user.OTHERNAMES)) {
          name = `${user.OTHERNAMES} ${name}`;
        }
      }
    }
    return name;
  }

  decryptFromLocalStorage(key, json = true) {
    let encryptedKey = this.encryptData(key, encryptDecryptKeyPassword);
    if (this.valueIsDefined(localStorage.getItem(encryptedKey))) {
      if (json == true) {
        let result = "";
        try {
          result = JSON.parse(
            this.decryptData(
              localStorage.getItem(encryptedKey),
              encryptDecryptValuePassword
            )
          );
        } catch (err) {
          console.log(err, "json error");
        } finally {
          return result;
        }
      }
      else
        return this.decryptData(
          localStorage.getItem(encryptedKey),
          encryptDecryptValuePassword
        );
    } else {
      return "";
    }
  }

  // For Encryption/Decryption
  encryptData(data, password) {
    let key128Bits100Iterations = CryptoJS.PBKDF2(password, salt, {
      keySize: 128 / 32,
      iterations: 100,
    });
    let encryptOutput = CryptoJS.AES.encrypt(data, key128Bits100Iterations, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();

    return encryptOutput;
  }

  decryptData(data, password) {
    let key128Bits100Iterations = CryptoJS.PBKDF2(password, salt, {
      keySize: 128 / 32,
      iterations: 100,
    });
    let decryptOutput = CryptoJS.AES.decrypt(data, key128Bits100Iterations, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);

    return decryptOutput;
  }

  valueIsDefined(value) {
    if (
      value === "" ||
      value === null ||
      value === "null" ||
      value === undefined ||
      value === "undefined"
    ) {
      return false;
    }
    return true;
  }

  // Renders the interface
  render(state, actions) {
    return super.render('logged-in-user', [
      h('span', {}, state.name),
    ]);
  }

}